const secrets = {
    bingKey: 'AgEWtd4Lq87bKw_NJoTHhtGpA0dZdSfNzPooHQIGL1s2IV_fBEbpOCLyPRySS7ag',
    thunderforestKey: '806d6b5082ce4c1b8d75a70ffb5abd45',
    sentryDSN: 'https://00000000000000000000000000000000@sentry.io/111111',
    mapillary: '0000000000000000000000000000000000000000000000000000',
    flickr: '00000000000000000000000000000000',
    google: 'AIzaSyAnQ069t3BIiqa5kMpFR7AacI8GN0r9gU0',
    karjalankarttaKey: '4NIgWBON5qRTx8ePtDbQNR0YojJkhlFB'
};

export default secrets;
